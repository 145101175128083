import { logErrorToServer } from "@/libs/logging.js";

const XHR_READYSTATE_DONE = 4;

const corsCheck = function (url) {
	return new Promise(function (resolve) {
		var req = new XMLHttpRequest();
		req.open("GET", url, true);

		function cb(corsAllowed) {
			var allowed = req.status !== 0 && corsAllowed;
			resolve({ url: url, corsAllowed: allowed });
		}

		req.onreadystatechange = function () {
			if (req.readyState === XHR_READYSTATE_DONE) cb(true);
		};
		req.onerror = function () {
			cb(false);
		};
		req.onload = function () {
			cb(true);
		};
		// These situations are unlcear - but I figure we'll detect failures in aggregate.
		req.ontimeout = function () {
			cb(false);
		};
		req.onabort = function () {
			cb(false);
		};
		req.send();
	});
};

let corsChecked = false;

// When we receive an error of "Script Error." it indicates that there is a cors
// restriction preventing us from seeing the contents. We'll do some work to try
// and identify the offending script, but there is no real information about which
// script even has an issue. So ultimately, report once, then supress.
window.onScriptError = function (msg) {
	console.error("Script Error", msg);
	// If you return true, then error alerts (like in older versions of
	// Internet Explorer) will be suppressed.
	var suppressErrorAlert = true;

	// Only run once.
	if (corsChecked || typeof Promise == "undefined") return suppressErrorAlert;

	corsChecked = true;

	// Get all of the scripts on the page, convert to an array (it was a NodeList)
	var scripts = Array.from(document.getElementsByTagName("script"));
	var sources = scripts // Array of script tags
		.map(function (script) {
			return script.src;
		}) // Array of script urls
		.filter(function (script) {
			return script != "";
		}) // Without empty urls (inlined typically)
		.sort(); // Sorted, so we have consistent aggregation in logs

	var extraData = {
		priority: "warning",
		message: msg,
	};

	// Run a corscheck on each script
	var corsPromises = sources.map(function (source) {
		return corsCheck(source);
	});
	// When they are all complete...
	Promise.all(corsPromises)
		.then(function (results) {
			var corsFailures = results // Array of {url: '...', corsAllowed: '...'}
				.filter(function (results) {
					return results.corsAllowed == false;
				}) // Keep only failures
				.map(function (results) {
					return results.url;
				}) // Array of just urls
				.join(";"); // String of ; separated urls

			extraData.scripts = sources.join(";");
			extraData.corsFailures = corsFailures;

			// log error to splunk
			logErrorToServer("JS Exception", extraData);
		})
		.catch(function (error) {
			extraData.corsCheckError = error.message;
			// log error to splunk
			logErrorToServer("JS Exception", extraData);
		});

	return suppressErrorAlert;
};

window.onerror = function (msg, url, line, col, error) {
	console.error("Script Error", msg);
	if (msg == "Script error.") {
		return window.onScriptError(msg);
	}

	var extraData = {
		priority: "warning",
		message: msg,
		line: line,
		col: col,
		error: error,
		url: url,
	};

	// log error to splunk
	logErrorToServer("JS Exception", extraData);
	console.log("JS Exception", extraData);

	// If you return true, then error alerts (like in older versions of
	// Internet Explorer) will be suppressed.
	return true;
};
