import "./main.css";
import "@/libs/errorHandler.js";
import "@/libs/unityCallbacks.js";
import { getWebglSupport } from "@/libs/glSupport.js";
import { onClick } from "@/libs/dom.js";
import { StartTheGame } from "@/game.js";
import { isMobile } from "@/libs/browser.js";
// Allows for async code.
import "regenerator-runtime/runtime.js";

import SetupFooter from "./ui/footer.js";
import SetupFullscreen from "./ui/fullscreen.js";

// Wait until the document is ready. Then setup the content!
document.addEventListener("DOMContentLoaded", function () {
	// Disable dragging on the playnow image. Otherwise, you
	// can accidentally drag it instead of clicking it.
	document.getElementById("playNow").ondragstart = function () {
		return false;
	};

	// Force rendering to standard DPI. In Unity 2019.3, high DPI support was added but it causes issues with click registering.
	window.devicePixelRatio = 1;

	SetupFooter();
	SetupFullscreen();

	const glSupport = getWebglSupport();
	if (glSupport.state == "webgl_supported_and_working") {
		if (!isMobile() && typeof localStorage.returningPlayer !== "undefined") {
			StartTheGame();
		}

		onClick("img#playNow", () => {
			localStorage.returningPlayer = true;
			if (!isMobile()) {
				StartTheGame();
			} else {
				window.open(
					"https://hititrich.onelink.me/wrlL/dotcommobile",
					"_blank"
				);
			}
		});
	} else {
		document.body.className = "GLError";
		console.log("WEBGL NOT SUPPORTED :/");

		return;
	}
});
