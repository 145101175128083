import { loadScript } from "@/libs/dom.js";
import { UserFlow } from "@/libs/userFlow.js";
import Profiler from "@/libs/profiler.js";
import UnityLogParser from "@/libs/unityLogParser.js";
import { deleteIndexedDB } from "@/libs/indexedDB.js";
import ajax from "@/libs/ajax.js";
import qs from "@/libs/queryString.js";
import exposeToUnity from "@/libs/exposeToUnity.js";
import config from "@/libs/config.js";
import { logErrorToServer } from "@/libs/logging.js";

function setupCanvas() {
	const gameContainer = document.getElementById('game');
	const gameCanvas = document.createElement("canvas");
	gameCanvas.style.width = "100%";
	gameCanvas.style.height = "100%";
	gameCanvas.addEventListener("contextmenu", function (e) { e.preventDefault() });
	gameCanvas.addEventListener("dragstart", function (e) { e.preventDefault() });
	gameCanvas.id = "#canvas";
	gameContainer.appendChild(gameCanvas);
	// yuck!
	window.gameCanvas = gameCanvas;
	window.gameContainer = gameContainer;
}
export async function StartTheGame() {


	// Kick off the preloading state.
	document.body.className = "preload";

	const { code, response } = await ajax({
		url: `https://${config.server}/client/init.php`,
	});

	if (code !== 200) {
		// Its unclear what is wrong. This really should work 100% of the time.
		logErrorToServer("Failed getting init data.", {code: code, response: response});

		// This is a generic "OOPS Somthings wrong" message.
		document.body.className = 'GLError';
		return;
	}

	const init = response;
	let gameInfo;

	try {
		// This is NOT parsed below because Unity handles that.
		gameInfo = JSON.parse(init.gameInfo);
		config.dataVersion = gameInfo.basic_game_data.data_version;

		// override if the player should play with HD asset
		gameInfo.config.hd_assets = determineBundleVariantToUse(init);

		// Extract necessary data.
		const CDN_URL = gameInfo.basic_game_data.static_asset_hosts_v2[0][0];
		const STREAMING_PATH =
			gameInfo.basic_game_data.mobile_streaming_assets_base_url_v2;
		const LOADING_IMAGE =
			gameInfo.basic_game_data.live_data.DYNAMIC_LOADING_JSON.background;

		const backgroundElement = document.querySelector(".preload img.background");
		backgroundElement.src = `https://${CDN_URL}${STREAMING_PATH}Images/${LOADING_IMAGE}`;

	} catch (e) {
		logErrorToServer("Error parsing basic game data ", {message: e.message, stack: e.stack});
	}

	// Allow a version override from the url.
	let version = qs("unityVersion", init.clientBuild);
	version = UNITY_BUILD ? "local" : version;

	setupCanvas();

	// Build config
	const clientUrl = `https://socialslots.cdn.zynga.com/webgl/clients/${version}`;
	const unityConfig = {
		dataUrl: `${clientUrl}/Build/webgl.data.unityweb`,
		frameworkUrl: `${clientUrl}/Build/webgl.framework.js.unityweb`,
		codeUrl: `${clientUrl}/Build/webgl.wasm.unityweb`,
		streamingAssetsUrl: `${clientUrl}/StreamingAssets`,
		companyName: "Zynga",
		productName: "Hit It Rich",
		productVersion: version,
	};

	let UnityLoaderScript = `${clientUrl}/Build/webgl.loader.js`;
	let webglJsonPath = `${clientUrl}/Build/webgl.json`;

	// Override for development builds.
	if(version == "local") {
		if(UNITY_BUILD) {
			UnityLoaderScript = window.UNITY_WEBGL_LOADER_URL;
			webglJsonPath = window.UNITY_WEBGL_BUILD_URL;
		} else {
			UnityLoaderScript = `/webgl/Build/webgl.loader.js`;
			webglJsonPath = "/webgl/Build/webgl.json";
		}
	}

	config.clientVersion = version;
	console.log(
		`Starting the game via loader: '${UnityLoaderScript}' and webgl.json: '${webglJsonPath}'`
	);

	loadScript(UnityLoaderScript)
		.then(() => {
			const zid = null;
			UserFlow.init(
				init.clientBuild,
				init.userFlowSessionKey,
				zid,
				init.os,
				init.userAgentParent
			);
			window.addEventListener("focus", Profiler.onLoadingFocus);
			window.addEventListener("blur", Profiler.onLoadingBlur);
			Profiler.beginLoad();

			// Client grabs gameInfo from the window.
			const gameInfoJson = JSON.stringify(gameInfo);
			exposeToUnity("gameInfo", gameInfoJson);
			console.log(`gameInfo: '${gameInfoJson}'`);

			// Set up the userflow start obj
			const userflowStartObj = UserFlow.getUserFlowObject(
				"loading-webgl",
				"start"
			);
			const moduleObj = {};

			moduleObj.onRuntimeInitialized = emitUnityLoaderSplunkTimings;

			// Handle various loader controls.
			if (qs("useWasm", "true") == "false") {
				// Use the browser's web assembler.
				window.UnityLoader.SystemInfo.hasWasm = false;
			}
			if (qs("useIndexedDB", true) == "false") {
				// Disable use of cached assets.
				moduleObj.CachedXMLHttpRequestDisable = true;
			}
			if (qs("cacheLoader", "false") == "true") {
				// Use cached loader
				moduleObj.CachedXMLHttpRequestLoader = true;
			}
			// Ensure the client function exists before we try to call it.
			if (qs("cleanDB", "false") == "true") {
				deleteIndexedDB();
			}

			moduleObj.backgroundColor = "";

			// Make the first userflow loading log.
			UserFlow.handleUserflowLog(userflowStartObj);

			// Put some smarts into our logs
			moduleObj.print = UnityLogParser;

			window.createUnityInstance(window.gameCanvas, unityConfig, zyOnUnityProgress).then(function(unityInst) {
				window.gameInstance = unityInst;
				hideProgressBar(window.gameCanvas)
			});

			Profiler.logTimeSinceLoad("gameInstance created");
		})
		.catch((e) => {
			console.log("Caught Exception", e);
		});
}

/**
 * Hook from unity to get progress updates
 */
function zyOnUnityProgress(progress) {
	try {
		updateProgressBar(window.gameCanvas, progress);
	} catch (exception) {
		console.log("Caught Exception", exception);
	}
}

function updateProgressBar(canvas, progress) {
	if (!canvas) {
		return;
	}
	constructProgressBar(canvas);
	canvas.progress.empty.full.style.width = (100 * progress) + "%";
}

function constructProgressBar(canvas) {
	if (canvas.progress) {
		return;
	}

	canvas.progress = document.createElement("div");
	canvas.progress.className = "progress";
	canvas.progress.id = "progress"

	canvas.progress.empty = document.createElement("div");
	canvas.progress.empty.className = "empty";
	canvas.progress.appendChild(canvas.progress.empty);

	canvas.progress.empty.full = document.createElement("div");
	canvas.progress.empty.full.className = "full";
	canvas.progress.empty.appendChild(canvas.progress.empty.full);
	window.gameContainer.appendChild(canvas.progress);
}

function hideProgressBar(canvas) {
	if (canvas.progress) {
		window.gameContainer.removeChild(canvas.progress);
		canvas.progress = undefined;
	}
}


// Call this as a onRuntimeInitialized callback from the UnityLoader...
// It gets the js UnityLoader job timings and submits them to a splunk userflow
function emitUnityLoaderSplunkTimings() {
	console.log("emitUnityLoaderSplunkTimings...");

	var props = getUnityLoaderJobTimings();
	props["flow_key"] = "loading-webgl";
	props["flow_state"] = "jsloader";
	props["session_key"] = String(window.userflowSessionKey);
	props["idb_deleted"] = String(window.wasIndexedDBDeleted);
	props["using_wasm"] = String(window.gameInstance.Module.usingWasm);
	props["cache_loader"] = String(
		window.gameInstance.Module.CachedXMLHttpRequestLoader
	);
	props["disable_idb"] = String(
		window.gameInstance.Module.CachedXMLHttpRequestDisable
	);

	if (window.logEventToServer != null) {
		window.logEventToServer("Userflow", "loading-webgl-jsloader", props, 1);
	} else {
		console.warn(
			"could not find function window.logEventToServer; not emitting splunk event"
		);
	}
	return props;
}

// Extracts the js UnityLoader download + processing jobs with their start/end timestamps,
// Return them in an Object we can provide to Splunk.
function getUnityLoaderJobTimings() {
	var timings = {};
	for (var id in window.gameInstance.Module.Jobs) {
		var job = window.gameInstance.Module.Jobs[id];

		// fixup because final job.endtime isn't written until after intialization callback runs...
		if (job.executed && isNaN(job.endtime)) {
			job.endtime = performance.now();
			console.log("patching in missing endtime for completed job " + id);
		}
		// convert performance times (MS) to epoch times in seconds with 3 decimal places
		var timeOrigin = performance.timeOrigin ? performance.timeOrigin : 0; // undefined in Safari
		var starttime = Math.floor(job.starttime + timeOrigin) / 1000;
		var endtime = Math.floor(job.endtime + timeOrigin) / 1000;
		var elapsedSec = endtime - starttime;

		console.log(
			"job=" +
				id +
				"  executed=" +
				job.executed +
				"  seconds=" +
				elapsedSec +
				"  start=" +
				starttime +
				"  end=" +
				endtime
		);
		timings[id + "_start"] = starttime;
		timings[id + "_end"] = endtime;
	}
	return timings;
}

// Determine if this local player should use HD assets or not
function determineBundleVariantToUse(initResponse) {
	// override asset bundle variant to use if specified
	const bundleVariant = qs("bundleVariant", "none");

	let usingHD = false;
	if(bundleVariant !== "none")
	{
		usingHD = (bundleVariant.toUpperCase() === 'HD');
		console.log(`determineBundleVariantToUse: bundleVariant='${bundleVariant}' usingHD='${usingHD}'`);
	} else {
		let id = window.localStorage.getItem('initId')
		if(id === null) {
			id = (Math.floor(Math.random() *100));
			window.localStorage.setItem('initId', id);
		} else {
			id = +id;
		}
		usingHD = (id < initResponse.webGLHDPlayerPercent);
		console.log(`determineBundleVariantToUse: initId='${id}' hdPercent='${initResponse.webGLHDPlayerPercent}' usingHD='${usingHD}'`);
	}
	return usingHD;
}