/* global SERVER */

// Get the current host to connect the client to.
// This will be provided via webpack in production. Otherwise,
// we should be able to use the hostname from the browser.
let server = (typeof SERVER !== 'undefined') ? SERVER : location.host;

// This will be used to pass around configuration data as well.
// Since the export is an object, and in JS objects are passed
// by ref, others can set values here to be used as necessary.
export default {
	server: server,
	// This is a default that is overridden when we have user data.
	supportUrl: "https://zyngasupport.helpshift.com/a/hit-it-rich/?l=en-us",
};
