import { isBrowserIE } from "./browser.js";

export function getWebglSupport() {
	// Default state.
	var glVersionUsed = 0;
	var glState = "webgl_not_supported";
	var maxGlVersionSupported = 0;

	// If the browser provides the WebGL2RenderingContext or WebGLRenderingContext
	// we can infer the browser itself supports WebGLv2 or WebGLv1.
	if (window.WebGL2RenderingContext) {
		maxGlVersionSupported = 2;
	} else if (window.WebGLRenderingContext) {
		maxGlVersionSupported = 1;
	}

	// All of the names the webgl context might be called (experimental is on older browsers).
	var possibleNames = [
		{ name: "webgl2", glVersion: 2 },
		{ name: "experimental-webgl2", glVersion: 2 },
		{ name: "webgl", glVersion: 1 },
		{ name: "experimental-webgl", glVersion: 1 },
	];

	// Figure out which version of webgl we can get a canvas for.
	var canvas = document.createElement("canvas");
	if (maxGlVersionSupported) {
		glState = "webgl_supported_but_not_working";
		for (var i = 0; i < possibleNames.length; i++) {
			if (possibleNames[i].glVersion > maxGlVersionSupported) {
				continue;
			}

			if (canvas.getContext(possibleNames[i].name)) {
				// Found a good context.
				glVersionUsed = possibleNames[i].glVersion;
				glState = "webgl_supported_and_working";
				break;
			}
		}
	}

	// blacklisting IE browser
	if (isBrowserIE()) glState = "webgl_not_supported";

	return {
		works: glVersionUsed !== 0,
		version: glVersionUsed,
		maxVersion: maxGlVersionSupported,
		state: glState,
	};
}
