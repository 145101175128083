// purchase wrapper for one click purchase
import { show } from "@/libs/dom.js";
import { sendToStats } from "@/libs/stats.js";

const qs = (...params) => document.querySelector(...params);

export default function UpdateOneClickBuy(
	fbId,
	hasOneClickBuyPackage,
	oneClickBuyPackageName,
	oneClickBuyPackageBonus,
	oneClickBuyVariant,
	bonusDisplay,
	offerDisplay
) {
	if (!hasOneClickBuyPackage) {
		return;
	}

	// Update the display of the discount message and description
	const discountElement = qs("#discount_message") || {};
	discountElement.innerText = bonusDisplay;
	const offerElement = qs("#ocb_desc") || {};
	offerElement.innerText = offerDisplay;

	// Setup onclick handler
	const buyButton = qs("#one_click_short_buy_button") || {};
	buyButton.onclick = !hasOneClickBuyPackage
		? null
		: () => {
				// Send the purchase click to the client for processing.
				window.gameInstance.SendMessage(
					"Persistent Objects",
					"purchasePopcornSalePackage",
					JSON.stringify({
						itemCode: oneClickBuyPackageName,
						bonusPercent: oneClickBuyPackageBonus,
						economyName: "one_click_buy",
						variantName: oneClickBuyVariant,
					})
				);

				const statsBatch = {
					stats: [
						{
							type: "handle_count",
							parameters: {
								counter: "top_nav",
								kingdom: "one_click_buy",
								phylum: oneClickBuyPackageName,
								genus: "click",
							},
						},
					],
				};

				sendToStats(fbId, JSON.stringify(statsBatch));
			};

	show("#one_click_buy_panel");
}
