// Respond to clicks on a selector. NOTE: technically clicks propigate up the dom.
// This means that clicking on a dialog will generally propigate up to the shroud.
// respondToDirectClickOnly will help prevent this behavior
export function onClick(selector, callback, respondToDirectClickOnly) {
	respondToDirectClickOnly =
		typeof respondToDirectClickOnly === "undefined"
			? false
			: respondToDirectClickOnly;

	const elements = document.querySelectorAll(selector);
	if (elements.length === 0) {
		console.log(`Element '${selector}' not found.`);
		return;
	}

	for (const element of elements) {
		if (respondToDirectClickOnly) {
			element.addEventListener("click", (evt) => {
				if (evt.target === element) {
					callback(evt);
				}
			});
		} else {
			element.addEventListener("click", callback);
		}
	}
}

// Adds the `hidden` class to an element
export function hide(selector) {
	const element = document.querySelector(selector);
	if (!element) {
		console.log(`Element '${selector}' not found.`);
		return;
	}

	element.classList.add("hidden");
}

// Removes the `hidden` class to an element
export function show(selector) {
	const element = document.querySelector(selector);
	if (!element) {
		console.log(`Element '${selector}' not found.`);
		return;
	}

	element.classList.remove("hidden");
}

// Provide a URL and you'll get a script loaded and be notified
// via promise when its complete!
export function loadScript(url) {
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.onload = resolve;
		script.onerror = reject;

		script.src = url;
		document.getElementsByTagName("head")[0].appendChild(script);
	});
}
