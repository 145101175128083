import ajax from "@/libs/ajax.js";
import config from "@/libs/config.js";

// These are messages that we don't need to report.
const blacklist = [
	"Unable to get property 'x' of undefined or null reference",
	"Unable to get property 'alpha' of undefined or null reference",
];

// send an error to log_error.php for tracking in Splunk
export function logErrorToServer(msg, extraInfo) {
	//skip a noisy bursty Edge log
	//I hate to be doing this but there is no way around it
	//in unity generated code, accelerator.x and rotationRate.alpha are accessed and that gets caught by us
	//reducing some noise
	if (
		typeof extraInfo === "object" &&
		typeof extraInfo.message !== "undefined" &&
		blacklist.indexOf(extraInfo.message) !== -1
	) {
		return;
	}

	ajax({
		url: `https://${config.server}/client/log_error.php`,
		method: "POST",
		data: { error: { message: msg, extra_info: extraInfo } },
		dataType: "application/json",
		async: true,
	});
}

// send an error to log_error.php for tracking in Splunk
export function logEventToServer(type, name, fields, sampleRate) {
	ajax({
		url: `https://${config.server}/client/log_event.php`,
		method: "POST",
		data: {
			batch: [
				{ type: type, name: name, fields: fields, sample_rate: sampleRate },
			],
		},
		dataType: "application/json",
		async: true,
	});
}
