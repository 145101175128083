import { onClick, show, hide } from "@/libs/dom.js";
import config from "@/libs/config.js";

export default function () {
	onClick("#help_wrapper",
		() => hide("#help_wrapper"),
		true
	);

	// Open the support page.
	onClick(".footer_help_link", () => {
		// There is a customized frame that is provided when a user is logged in. It automatically adds
		// their zid to support tickets. That will be provided in SUPPORT_URL. Otherwise, don't use the frame.
		const url = config.supportUrl;
		if (url.length > 0) {
			const iframe = document.getElementById("helpshift_frame");
			if (iframe) {
				iframe.src = url;
				show("#help_wrapper");
			}
		}
	});
}
