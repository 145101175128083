export function deleteIndexedDB() {
	if (!indexedDB) {
		return console.warn("indexedDB is not available");
	}
	var openDB;
	try {
		openDB = indexedDB.deleteDatabase("CachedXMLHttpRequest");
	} catch (e) {
		console.warn("indexedDB access denied for deletion");
		return;
	}
	openDB.onsuccess = function () {
		window.wasIndexedDBDeleted = true;
	};
	openDB.onerror = function (e) {
		console.log("Failed to delete IndexedDB", e);
	};
}
