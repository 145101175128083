// Context: Unity normally writes to a TTY on other systems. This would be stderr/stdout.
// When converting to WebGL - Emscripten (or Unity) creates an emulation of a TTY. It creates
// a file object and writes to it. In the process, entire multiline messages get written out
// character by character. The virtual file reconsistutes that into line based messages.
//
// The result is a single multiline output gets output as single lines to the console. This is
// slow because of the rate at which we log, but also makes it challenging to get a sense
// of what is going on in the logs.
//
// This log parser attempts to rebuild those logs into their grouped format, then adjust
// the logging level by inferring based on contents. IT IS NOT PERFECT.
let printBuffer = "";

export default function UnityLogParser(message) {
	// Empty message is sign of end of current log
	if (message == "") {
		// Match lines like this:
		// 2021-01-27 13:27:33.0520000-06:00 [Zynga.Zdk.Services.Track.BatchedTrackService] TRACE: LogCount called for counter zis_auth.
		const result = printBuffer.match(
			/^[^\n]*(TRACE|WARN|ERROR|INFO|EXCEPTION):/
		);
		if (result) {
			switch (result[1]) {
				case "TRACE":
					console.log(printBuffer.split("\n")[0]);
					break;
				case "INFO":
					console.log(printBuffer);
					break;
				case "WARN":
					console.warn(printBuffer);
					break;
				default: // Default is an error because it should never happen?
				case "EXCEPTION":
				case "ERROR":
					console.error(printBuffer);
					break;
			}
		} else {
			console.log(printBuffer);
		}

		// We logged out the contents of the buffer, so clear it.
		printBuffer = "";
		return;
	}

	// Append to the buffer.
	printBuffer += message + "\n";
}
