import ajax from "@/libs/ajax.js";
import config from "@/libs/config.js";

export function sendToStats(fbId, batch) {
	ajax({
		url: `https://${config.server}/client/stats.php`,
		method: "POST",
		data: { fb_id: fbId, batch: batch },
		dataType: "text",
		async: true,
	});
}

export const Stats = {
	trackCount: function (fbId, counter, kingdom, genus) {
		sendToStats(
			fbId,
			JSON.stringify({
				stats: [
					{
						type: "handle_count",
						parameters: { counter, kingdom, genus },
					},
				],
			})
		);
	},
};
