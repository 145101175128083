import { UserFlow } from "./userFlow.js";
import { logEventToServer } from "./logging.js";

const Profiler = {
	loadStart: 0,
	verboseLog: function (message) {
		console.log(message);
	},
	beginLoad: function () {
		Profiler.loadStart = new Date().getTime();
		Profiler.verboseLog("start time " + Profiler.loadStart);
	},
	timeSinceLoad: function () {
		if (Profiler.loadStart == 0) {
			console.warn("Profiler not initialized");
			return 0;
		}
		return new Date().getTime() - Profiler.loadStart;
	},
	logTimeSinceLoad: function (message) {
		var logMessage = "js_load_time=" + Profiler.timeSinceLoad();
		if (typeof message != "undefined") {
			logMessage = message + " " + logMessage;
		}
		Profiler.verboseLog(logMessage);
		return logMessage;
	},
	logTimeToserver: function (message) {
		message = message || "";
		Profiler.logTimeSinceLoad(message);
		logEventToServer(
			"Profiler",
			message,
			{ js_load_time: Profiler.timeSinceLoad() },
			"1"
		);
	},
	onLoadingBlur: function () {
		UserFlow.BlurCounter++;
		Profiler.logTimeSinceLoad("step=blur");
		if (UserFlow.BlurCounter < 5 || UserFlow.BlurCounter % 5) {
			var flowObject = UserFlow.getUserFlowObject(
				UserFlow.FlowKeys.load,
				"window_blur"
			);
			flowObject.blur_count = UserFlow.BlurCounter;
			UserFlow.handleUserflowLog(flowObject, true);
		}
	},
	onLoadingFocus: function () {
		Profiler.logTimeSinceLoad("step=focus");
		if (UserFlow.BlurCounter < 5 || UserFlow.BlurCounter % 5) {
			var flowObject = UserFlow.getUserFlowObject(
				UserFlow.FlowKeys.load,
				"window_focus"
			);
			flowObject.focus_count = UserFlow.BlurCounter;
			UserFlow.handleUserflowLog(flowObject, true);
		}
	},
};

export default Profiler;
