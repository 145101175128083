const XHR_READYSTATE_DONE = 4;
const HTTP_RESPONSE_OK = 200;


// If we have data, expect it to be an object,
// and convert it to FormData. This code is designed
// to work like jquery's ajax handler. Since thats what we used
// to do.
function buildFormData(formData, data, parentKey) {
	if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
		if(Array.isArray(data)) {
			let index = 0;
			Object.keys(data).forEach(key => {
				buildFormData(formData, data[key], parentKey ? `${parentKey}[${index}]` : key);
				index++;
			});
		} else {
			Object.keys(data).forEach(key => {
				buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
			});
		}
	} else {
		const value = data == null ? '' : data;

		formData.append(parentKey, value);
	}
}

// This is a simple ajax handler. It is not as "complete" as jquery's
// ajax handler, or axios/request2/etc. It will, however, fit most
// of our uses with less overhead.
export default function ajax({ url, method, async, dataType, data }) {
	method = method || "GET";
	dataType = dataType || "json";
	async = typeof async !== "undefined" ? async : true;

	const formData = new FormData();
	buildFormData(formData, data);

	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open(method, url, async);
		xhr.send(formData);

		xhr.onreadystatechange = function () {
			if (xhr.readyState === XHR_READYSTATE_DONE) {
				if (xhr.status === HTTP_RESPONSE_OK) {
					let response;
					switch (dataType) {
						case "text":
							response = xhr.responseText;
							break;
						default:
							try {
								response = JSON.parse(xhr.responseText);
							} catch (err) {
								reject(err);
							}
					}
					resolve({ code: xhr.status, response: response, xhr: xhr });
				} else {
					reject({ code: xhr.status, response: xhr.response, xhr: xhr });
				}
			}
		};
	});
}
