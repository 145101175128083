/*
 * This function handles a userflow request and passes it along to the server.
 * It is ONLY to be used in cases where the canvas starts the initial userflow log.
 */
import { logEventToServer } from "./logging.js";
import exposeToUnity from "@/libs/exposeToUnity.js";

export const UserFlow = {
	BlurCounter: 0,
	FlowKeys: {
		load: "loading-webgl",
	},
	userflowObj: {},
	userFlowTimestamps: {}, // Var to store prior flowstate timestamps by flowkey. Needs to exist beyond function scope.
	init: function (version, session_key, zid, os, browser) {
		UserFlow.userflowObj.flow_number = "0";
		UserFlow.userflowObj.version = version;
		UserFlow.userflowObj.session_key = session_key;
		UserFlow.userflowObj.user_id = zid;
		UserFlow.userflowObj.os = os;
		UserFlow.userflowObj.browser = browser;
		UserFlow.userflowObj.client_type = "webgl";
	},
	getUserFlowObject: function (flow_key, flow_state) {
		if (typeof flow_key == "undefined" || typeof flow_state == "undefined") {
			return {};
		}
		// Set up the userflow start obj
		return {
			//flow_key: "loading-webgl",
			flow_key: flow_key,
			//flow_state: "start",
			flow_state: flow_state,
			flow_number: UserFlow.userflowObj.flow_number,
			version: UserFlow.userflowObj.version,
			session_key: UserFlow.userflowObj.session_key,
			client_type: UserFlow.userflowObj.client_type,
			user_id: UserFlow.userflowObj.user_id,
			os: UserFlow.userflowObj.os,
			browser: UserFlow.userflowObj.browser,
		};
	},
	handleUserflowLog: function (flowData, skipTimestamp) {
		var flowKey = flowData["flow_key"];
		var flowState = flowData["flow_state"];

		// Store the flow state timestamp.
		if (!UserFlow.userFlowTimestamps[flowKey]) {
			UserFlow.userFlowTimestamps[flowKey] = {};
		}

		// Store all extra properties from the start userflowlog for use in all subsequent matching logs.
		if (flowState == "start") {
			for (var prop in flowData) {
				if (
					Object.prototype.hasOwnProperty.call(flowData, prop) &&
					prop != "flow_key" &&
					prop != "flow_state"
				) {
					UserFlow.userFlowTimestamps[flowKey][prop] = flowData[prop];
				}
			}
		}

		if (typeof skipTimestamp == "undefined" || !skipTimestamp) {
			// mobile client wants time in seconds with decimal centiseconds
			var flowStateTime = flowState.replace(/-/g, "_") + "_time"; // can't send dashes in keynames
			var timestamp = new Date().getTime() / 1000; // get seconds.milliseconds.
			timestamp = timestamp.toFixed(2); // truncate milliseconds to centiseconds.
			UserFlow.userFlowTimestamps[flowKey][flowStateTime] = timestamp;
		}

		// Add the timestamp and all prior stored data for the flowkey to the new userflow log.
		flowData = Object.assign(flowData, UserFlow.userFlowTimestamps[flowKey]);

		// Set duration if we're on the end.
		if (flowState == "end") {
			flowData["duration"] = (
				flowData["end_time"] - flowData["start_time"]
			).toFixed(2); //seconds.centiseconds

			//special handling for loading event
			if (flowKey == UserFlow.FlowKeys.load) {
				flowData["blur_count"] = UserFlow.BlurCounter;
			}
		}

		// Log the log.
		logEventToServer("Userflow", flowKey + "-" + flowState, flowData, "1");
	},
};

exposeToUnity("handleUserflowLog", UserFlow.handleUserflowLog);
