import Profiler from "@/libs/profiler.js";
import exposeToUnity from "@/libs/exposeToUnity.js";
import UpdateOneClickBuy from "@/ui/oneClickBuy.js";
import { show } from "@/libs/dom.js";
import config from "@/libs/config.js";

exposeToUnity("reloadGame", () => window.location.reload());

let endLoadingSlideShowCount = 0;
// This is called twice by the Unity loading flow.
// First, when client starts the loading screen, second when lobby becomes interactive.
exposeToUnity("endLoadingSlideShow", () => {
	// Add the resize handler.
	if (endLoadingSlideShowCount == 0) {
		// Run any delayed page setup needed.
		window.addEventListener("resize", function () {
			window.gameResize();
		});

		// Set body to display game state.
		document.body.className = "game";

		Profiler.logTimeToserver(
			"step=endLoadingSlideShow" + endLoadingSlideShowCount
		);
		endLoadingSlideShowCount++;
	}

	Profiler.logTimeToserver(
		"step=endLoadingSlideShow" + endLoadingSlideShowCount
	);
	endLoadingSlideShowCount++;
});

let resizeTimer;
// Called in JS to adjust to the size given.
// Called by Unity when the resolution changes during Awake().
exposeToUnity("gameResize", () => {
	if (resizeTimer) {
		clearTimeout(resizeTimer);
	}

	resizeTimer = setTimeout(function () {
		var divGame = document.getElementById("game");
		var newWidth = divGame.parentNode.parentNode.offsetWidth;
		divGame.style.width = newWidth + "px";
		window.gameInstance.SendMessage(
			"Persistent Objects",
			"browserResize",
			newWidth
		);
	}, 250);
});

exposeToUnity("initPredownloader", () => {
	Profiler.logTimeToserver("step=initPredownloader");

	//remove eventlisteners, only need them during loading time
	window.removeEventListener("focus", Profiler.onLoadingFocus);
	window.removeEventListener("blur", Profiler.onLoadingBlur);
});

exposeToUnity("predownloaderComplete", () => {
	Profiler.logTimeToserver("step=predownloaderComplete");
});

exposeToUnity("UpdateWebGLData", (data) => {
	UpdateOneClickBuy(
		data.fbId,
		data.hasOneClickBuyPackage,
		data.oneClickBuyPackageName,
		data.oneClickBuyPackageBonus,
		data.oneClickBuyVariant,
		data.bonusDisplay,
		data.offerDisplay
	);

	const label = document.querySelector("#zid_show label");
	label.innerHTML = `ZID: ${data.zid}`;

	const pdrLink = document.querySelector("#bottom_pdr_link a");
	pdrLink.href = data.personalDataRequestURL;
	show("#bottom_pdr_link");

	if (DEBUG) {
		const debugEl = document.createElement("div");
		debugEl.style = "margin-top: 10px; font-size: 10px";
		debugEl.className = "selectableContent";
		debugEl.innerHTML = `
			Unityweb Client: ${config.clientVersion} <br>
			Data Version: ${config.dataVersion} <br>
			ZID: ${data.zid} FBID: ${data.fbId} <br>
			VIP Level: ${data.vipLevel} <br>
		`;

		// null indicates the end. This allows us to stick this div
		// at the bottom of the body. :)
		document.body.insertBefore(debugEl, null);
	}

	config.supportUrl = data.personalSupportURL;
});
