const userAgent = navigator.userAgent || navigator.vendor || window.opera;

function isIpad() {
	// Newer ipad's report userAgent, intentionally, like a desktop browser. They want to be
	// treated like a real browser and are attempting to force it on the world.
	//
	// This only works until apple makes a touchsceen enabled mac. Hopefully never. :)
	return (
		(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0) ||
		navigator.platform === "iPad"
	);
}

export function isIOS() {
	return /iPhone|iPod/i.test(userAgent) || isIpad();
}

export function isAndroid() {
	// Kindle sometimes reports as Android
	return !isKindle() && /Android/i.test(userAgent);
}

export function isKindle() {
	return /Kindle/i.test(userAgent);
}

export function isMobile() {
	// This is not comprehensive. If we want something comprehesive, this gets much more complicated.
	return isIOS() || isAndroid() || isKindle();
}

export function isBrowserIE() {
	// detect MSIE (from version 6 to 11)
	if (
		navigator.userAgent.indexOf("MSIE") !== -1 ||
		navigator.appVersion.indexOf("Trident/") > -1
	) {
		return true;
	}
	return false;
}
